import { sanityImage } from "$src/lib/sanity";
import type { SanityImageAssetDocument } from "next-sanity";
import { cx } from "@tracksuit/utils";
import tileStyles from "../testimonial-tile.module.css";
import styles from "./testimonial-image-tile.module.css";
import { type ComponentProps } from "react";

/**
 * @component
 * image tile for testimonials
 */

type TestimonialImageTileProps = {
  image: SanityImageAssetDocument;
} & ComponentProps<"div">;

export function TestimonialImageTile({
  image,
  className,
  ...props
}: TestimonialImageTileProps) {
  return (
    <>
      {image ? (
        <div
          className={cx(
            tileStyles["testimonial-tile"],
            tileStyles.small,
            styles["testimonial-image-tile"],
            className
          )}
        >
          <img alt="" loading="lazy" {...sanityImage(image)} />
        </div>
      ) : null}
    </>
  );
}
