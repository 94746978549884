import type { SwiperOptions } from "swiper/types";

// Common swiper UX
const commonConfig: SwiperOptions = {
  // Always allow trackpad and x axis scroll
  mousewheel: {
    forceToAxis: true
  }
};

export default commonConfig;
