export function resolveDocumentPath({
  region,
  doc
}: {
  region?: string;
  doc: {
    _type: string;
    slug?: { current: string };
    [key: string]: any;
  };
}) {
  const withRegion = region ? `/${region}` : "";

  switch (doc?._type) {
    case "home":
      return `${withRegion}/`;
    case "page":
      // TODO: use proper slug type here
      return `${withRegion}/${doc?.slug}`;
    case "blog":
      return `${withRegion}/blog`;
    case "blogTopic":
      if (doc?.slug?.current === "tools-and-templates") {
        return `${withRegion}/blog/${doc?.slug.current}`;
      } else {
        return `${withRegion}/blog/topics/${doc?.slug?.current}`;
      }
    case "blogPost":
      return `${withRegion}/blog/posts/${doc?.slug?.current}`;
    case "toolsAndTemplates":
      return `${withRegion}/blog/tools-and-templates/${doc?.slug?.current}`;
    case "contact":
      return `${withRegion}/contact`;
    case "careers":
      return `${withRegion}/careers`;
    case "partnerForm":
      return `${withRegion}/partners/apply`;
    case "shorts":
      return `${withRegion}/shorts`;
    case "slug":
      return `${withRegion}/${doc?.current}`;
    default:
      return `${withRegion}/${doc?.slug?.current ?? doc._id}`;
  }
}
