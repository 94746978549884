import type { SchemaList } from "./schemas";

export const tracksuitTeams: SchemaList[] = [
  { title: "Customer Success", value: "Customer Success" },
  { title: "Engineering", value: "Engineering" },
  { title: "Marketing", value: "Marketing" },
  { title: "Operations", value: "Operations" },
  { title: "Partnerships", value: "Partnerships" },
  { title: "People", value: "People" },
  { title: "Product", value: "Product" },
  { title: "Research", value: "Research" },
  { title: "Sales", value: "Sales" }
];
