export type Region = "au" | "uk" | "us";
export type AllRegion = Region | "all";

export type RegionParams = {
  region: Region;
};

export const regions = [
  { title: "US", value: "us", id: "us" },
  { title: "UK", value: "uk", id: "uk" },
  { title: "AU/NZ", value: "au", id: "au" }
];
export const allRegions = [
  { title: "All Regions", value: "all", id: "all" },
  ...regions
];

export const defaultAllRegion = "all";
export const defaultRegion = "us";
