import {
  createClient,
  type SanityImageAssetDocument,
  type SanityDocument
} from "next-sanity";
import imageUrlBuilder from "@sanity/image-url";
import { resolveDocumentPath } from "@tracksuit/utils";

/**
 * Sanity API client
 */
export const sanity = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  useCdn: true,
  apiVersion: "2023-10-01"
});

/**
 * Link resolver
 */
export function resolveLink(region: string, link?: SanityDocument): string {
  if (link) {
    if (link.externalLink) {
      return link.externalLink;
    } else if (link.internalLink) {
      return resolveDocumentPath({ region, doc: link.internalLink });
    } else {
      return resolveDocumentPath({ region, doc: link });
    }
  }
  return "";
}

/**
 * Image helper
 */
// TODO: Mock stanity imageUrlBuilder and test this
/* istanbul ignore next */
export function sanityImage(image?: SanityImageAssetDocument) {
  if (!image) {
    return;
  }

  const builder = imageUrlBuilder(sanity),
    base = builder.image(image).auto("format").quality(75),
    resolutions = [],
    srcset = [];

  let prev = 96;

  while (prev <= 3000) {
    resolutions.push(2 * Math.round(prev / 2));
    prev *= 1 + 0.08 * 2;
  }

  for (const res of resolutions) {
    srcset.push(`${base.width(res).fit("max").toString()} ${res}w`);
  }

  return {
    src: base.toString(),
    srcSet: srcset.join(", "),
    width: image.metadata?.dimensions.width,
    height: image.metadata?.dimensions.height
  };
}

/**
 * Video helpers
 */
export function mux(id?: string) {
  return id ? `https://stream.mux.com/${id}.m3u8` : "";
}
