import type { UseHubSpotFormResponse } from "next-hubspot";
import { useEffect, useCallback, useRef } from "react";

const isActive = (e: HTMLFormElement) => {
  const cl = e.closest(".field");
  cl && cl.classList.add("active");
};

const isNotActive = (e: HTMLFormElement) => {
  if (e.tagName === "SELECT") return;
  const cl = e.closest(".field");
  cl && cl.classList.remove("active");
};
const isError = (e: HTMLFormElement) => {
  const cl = e.closest(".field");
  cl && cl.classList.add("error");
};

const isNotError = (e: HTMLFormElement) => {
  const cl = e.closest(".field");
  cl && cl.classList.remove("error");
};

const removeAnimation = (id: string) => {
  if (
    document.querySelector(`#${id}`)?.classList.contains("hubspot-form-init")
  ) {
    document.querySelector(`#${id}`)?.classList.remove("hubspot-form-init");
  }
};

export const useFormValidation = (
  hubSpotForm: Partial<UseHubSpotFormResponse>,
  formId: string,
  id: string,
  GAEventName?: string
) => {
  const myItems = useRef<HTMLFormElement[]>();
  const myForm = useRef<HTMLFormElement>();
  const UTMParams = useRef<any>();

  const { formCreated, loaded } = hubSpotForm;

  /**
   * Adds focus, active and error states
   */
  const inputHandler = useCallback(
    (e: Event) => {
      // Reset animation
      removeAnimation(id);

      const target = e.target as HTMLFormElement;

      // Next tick
      setTimeout(() => {
        // Check for error
        target.classList.contains("invalid")
          ? isError(target)
          : isNotError(target);
        // Check for active
        target.value || e.type === "focus"
          ? isActive(target)
          : isNotActive(target);
      }, 0);
    },
    [id]
  );

  const submitHandler = useCallback(() => {
    const dataLayer = window?.dataLayer;
    if (dataLayer && GAEventName) {
      dataLayer.push({ event: "custom-event", name: GAEventName });
    }
  }, [GAEventName]);

  const changeHandler = useCallback(() => {
    UTMParams.current = JSON.parse(
      window?.sessionStorage?.getItem("utm") ?? "[]"
    );

    myItems.current = [
      ...document.querySelectorAll(`#${id} .hs-input`)
    ] as HTMLFormElement[];

    // Find specific inputs and add handler
    myItems.current.forEach((input: HTMLFormElement) => {
      if (!input) return;

      // If value has been already saved, make sure label is up
      if (input?.value || input.tagName === "SELECT") {
        isActive(input);
      }

      if (
        ["text", "email", "tel"].includes(input.type) ||
        input.classList.contains("hs-fieldtype-textarea") ||
        input.tagName === "SELECT"
      ) {
        input?.addEventListener("focus", inputHandler);
        input?.addEventListener("blur", inputHandler);
        input?.addEventListener("change", inputHandler);
        input?.addEventListener("keypress", inputHandler);
      }

      if (input.name.startsWith("utm_")) {
        const data = UTMParams?.current?.find(
          (tag: Record<string, string>) => tag[0] === input.name
        );
        if (data && data.length === 2) {
          input.value = data[1];
        }
      }
    });
  }, [id, inputHandler]);

  const init = useCallback(() => {
    changeHandler();

    if (!myItems.current) return;

    const forms = hubspot.form.api.getForms() as any[];
    const form = forms.find((f: any) => f.id === formId).getContext();
    if (!myForm.current) {
      myForm.current = document.querySelector(form.target);
      myForm?.current?.addEventListener("submit", submitHandler);
      myForm?.current?.addEventListener("change", changeHandler);
    }
  }, [formId, submitHandler, changeHandler]);

  /**
   * Init Handlers on each field
   */
  useEffect(() => {
    if (formCreated && loaded) {
      // onReady
      hubspot.form.api.onFormReady(init);
    }
  }, [formCreated, loaded, init]);
};
