export type Colors = "Pink" | "Green" | "Purple" | "Red" | undefined;

export const colors = [
  { title: "Pink", value: "pink", id: "pink" },
  { title: "Green", value: "green", id: "green" },
  { title: "Purple", value: "purple", id: "purple" },
  { title: "Red", value: "red", id: "red" }
];

export const defaultColor = "pink";
